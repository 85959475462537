import { format, getTime, startOfYear, endOfYear } from "date-fns";
import { enGB, pl, uk } from "date-fns/locale";
import i18nService from "@/services/i18n.service";

const activeLang = i18nService.getActiveLanguage();
const languages = {
  en: enGB,
  pl: pl,
  ua: uk,
};

const DateService = {
  /**
   * Get the date in timestamp format
   * @returns string
   */
  getDateTimestamp(date) {
    const timestampInSeconds = getTime(new Date(date)) / 1000;

    return date ? timestampInSeconds : "";
  },

  /**
   * Get the convertered date
   * @returns string
   */
  getConverteredDate(timestamp, type = "date") {
    const locale = languages[activeLang];
    const date = new Date(timestamp * 1000);

    let options = "";

    if (type.includes("date")) {
      options = "dd.MM.yyyy";
    }

    if (type.includes("time")) {
      options = "HH:mm:ss";
    }

    if (type.includes("datetime")) {
      options = "HH:mm dd.MM.yyyy";
    }

    if (type.includes("dateWithFullMonth")) {
      options = "dd MMMM yyyy";
    }

    return format(date, options, { locale });
  },

  /**
   * Get current year date period
   * @returns string
   */
  getCurrentYearPeriod() {
    const currentDate = new Date();
    const startOfYearDate = startOfYear(currentDate);
    const endOfYearDate = endOfYear(currentDate);

    return {
      startDate: format(startOfYearDate, "yyyy-MM"),
      endDate: format(endOfYearDate, "yyyy-MM"),
    };
  },
};

export default DateService;
